<script>
  import { createEventDispatcher } from 'svelte';

  export let images = [];
  export let height = null;

  const dispatch = createEventDispatcher();

  let max_width = 100;

  $: max_width = `calc(${100 / (images.length > 1 ? 2 : 1)}% - ${2 * images.length}px)`;

  function click(index) {
    dispatch('click', index);
  }
</script>

<style>
  .gallery {
    display: flex;
    justify-content: center;
  }

  .thumbnails {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
  }

  .gallery .image {
    margin-bottom: 10px;
    margin-right: 2px;
  }

  .gallery .image:hover {
    cursor: pointer;
  }

  .gallery img {
    border: 1px solid #eee;
    max-width: 100%;
  }
</style>

<div class="gallery">
  <div class="thumbnails">
    {#each images as img, i (img.msrc || img.src)}
      <div class="image" style={height ? '' : `flex-basis: ${max_width}`}>
        <img
          {...img}
          {...height ? {height: height} : {}}
          src={img.msrc || img.src}
          on:click={() => click(i)}
        />
      </div>
    {/each}
  </div>
</div>
