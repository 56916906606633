<script>
  import { onMount } from 'svelte';

  const KEY = 'AL7mVk0BAAAAvC8-NAIAEeFuCxea5ocxEDh_-hkgOBvhOCYAAAAAAAAAAAAU6u5n7SEl982oyVV5URhe6fQ3Mg==';
  const URL = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=' + KEY;

  const CENTER = ['55.7460', '37.8384'];

  const MARKERS = [
    {
      geo:     ['55.7462', '37.8384'],
      options: {
        hintContent:    'ATDC',
        balloonContent: '<b>ATDC</b><br>Кетчерская, 13, стр. 2<br>'
      }
    }
  ];

  let map_node, script_node;

  onMount(() => {
    if (window.ymaps)
      init_map();
    else
      inject_yandex_map_script();
  });

  function inject_yandex_map_script() {
    if (window.ymaps)
      return;

    const node = document.createElement('script');
    node.src = URL;
    node.onload = init_map;
    node.onreadystatechange = init_map; // IE 6-8
    document.head.appendChild(node);
  }

  function init_map() {
    // https://pie.gd/test/script-link-events/
    // Prevent second render in IE 9-10, Opera 11.60
    if (map_node.children.length > 0)
      return;

    window.ymaps.ready(() => {
      var map = new ymaps.Map('map', { zoom: 13, center: CENTER });
      MARKERS.forEach(marker => {
        map.geoObjects.add(new ymaps.Placemark(marker.geo, marker.options));
      });
    });
  }
</script>

<div
  id="map"
  bind:this={map_node}
  style="width: 600px; height: 260px; border: 1px solid #d4d4d4; margin: 0 auto;"
>
</div>
